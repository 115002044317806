// Sample card from Airbnb
import { StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Image,
  LinkBox,
  LinkOverlay,
  Avatar,
  AvatarGroup,
} from "@chakra-ui/react";
import React from "react";

export const Post: React.FC<any> = (props) => {
  const property = {
    imageUrl: props.thumbnail.url,
    imageAlt: props.name,
    topics: props.topics,
    title: props.name,
    tagline: props.tagline,
    reviewCount: props.votesCount,
    rating: props.reviewsRating,
    url: props.website,
    featured: !!props.featuredAt,
    description: props.description,
    makers: props.makers,
    id: props.id,
    createdAt: props.createdAt,
  };

  const elMakers = property.makers.map(
    (
      maker: { name: string | undefined; profileImage: string | undefined },
      ix: React.Key | null | undefined
    ) => {
      return <Avatar key={ix} name={maker.name} src={maker.profileImage} />;
    }
  );

  return (
    <LinkBox
      d="flex"
      marginTop="4"
      alignItems="top"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      key={property.id}
      boxShadow="sm"
    >
      <Image
        align="center"
        boxSize="150px"
        objectFit="cover"
        src={property.imageUrl}
        alt={property.imageAlt}
      />

      <Box margin="10px" width="100%">
        <Box d="flex" alignItems="baseline">
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {property.topics.join(" •")}
          </Box>
        </Box>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
          textAlign="left"
        >
          <LinkOverlay href={property.url} target="_blank">
            {property.title}
          </LinkOverlay>
        </Box>

        <Box textAlign="left" fontSize="md">
          {property.tagline}
        </Box>

        <Box textAlign="left" color="gray.600" fontSize="sm">
          {property.description}
        </Box>

        <Box textAlign="left" color="gray.500" fontSize="xs" marginTop={1}>
          Posted at {new Date(property.createdAt).toLocaleDateString()}
        </Box>
      </Box>

      <Box
        minW="150px"
        textAlign="center"
        d="flex"
        flexDir="column"
        justifyContent="center"
      >
        <Box>
          {Array(5)
            .fill("")
            .map((_, i) => (
              <StarIcon
                key={i}
                color={i < property.rating ? "teal.500" : "gray.300"}
              />
            ))}
          <Box ml="2" color="gray.600" fontSize="sm">
            {property.reviewCount} votes
          </Box>
        </Box>

        <Box
          d="flex"
          flexDir="column"
          color="teal.500"
          fontSize="small"
          marginTop="3"
        >
          <AvatarGroup marginLeft={10} size="sm" max={5}>
            {elMakers}
          </AvatarGroup>{" "}
          {property.makers.length} maker(s)
        </Box>
      </Box>
    </LinkBox>
  );
};
