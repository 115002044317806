import { Select } from "@chakra-ui/react";
import React from "react";

const Sort = (props: any) => {
  const onChange = (e: any) => {
    if (!e.target.value) {
      return;
    }
    const values = e.target.value.split(",");
    props.onSort({
      sortField: values[0],
      sortType: values[1],
    });
  };

  return (
    <Select placeholder="Sort By" onChange={onChange}>
      <option value="createdAt,desc">Created DESC</option>
      <option value="createdAt,asc">Created ASC</option>
      <option value="votesCount,asc">Votes ASC</option>
      <option value="votesCount,desc">Votes DESC</option>
      <option value="reviewsRating,asc">Reviews ASC</option>
      <option value="reviewsRating,desc">Reviews DESC</option>
    </Select>
  );
};

export default Sort;
