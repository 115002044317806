import {
  FormControl,
  FormLabel,
  Input,
  Divider,
  Box,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import DatePicker from "../components/DatePicker";
import TTable from "../components/Table";

export const AddCrawler = (props: any) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onAdd = (e: any) => {
    e.preventDefault();
    props.onAdd({
      name: e.target.querySelector("#name").value,
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10),
      enabled: true,
    });
  };

  return (
    <Box d="flex">
      <Box
        w="100%"
        padding={3}
        borderWidth={1}
        borderColor="gray.300"
        borderRadius={5}
      >
        <TTable
          colHeads={[
            "Name",
            "Start Date",
            "End Date",
            "Remaining Count",
            "Enabled",
          ]}
          rows={props.crawlers}
          heading="Crawlers"
        />
      </Box>
      <Box
        w="100%"
        padding={3}
        borderWidth={1}
        borderColor="gray.300"
        borderRadius={5}
        marginLeft={3}
      >
        <form style={{ padding: "1rem" }} onSubmit={onAdd}>
          <FormControl id="name" m={2}>
            <FormLabel>Name</FormLabel>
            <Input type="text" id="name" />
            {/* <FormHelperText>Enter Name of the crawler</FormHelperText> */}
          </FormControl>
          <Divider orientation="horizontal" />
          <FormControl m={2}>
            <FormLabel htmlFor="published-date">Date Range</FormLabel>
            <DatePicker
              id="published-date"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
            {/* <FormHelperText>Select Date Range</FormHelperText> */}
          </FormControl>
          <Button
            type="submit"
            float="right"
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
          >
            Add Crawler
          </Button>
        </form>
      </Box>
    </Box>
  );
};
