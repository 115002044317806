// import Select from 'react-select';
// import { Select as ChakraSelect } from '@chakra-ui/react';
import { FormControl } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select from "./Select";
import env from "react-dotenv";

const Topics = (props: { onChange: (arg0: any) => void; }) => {
  const [topics, setTopics] = useState([]);

  const fetchData = async () => {
    const topics = await fetch(`${env.API_URL}/topics`).then((res) =>
      res.json()
    );
    const topicsOptions = topics.map((topic: any) => ({
      value: topic,
      label: topic,
    }));
    setTopics(topicsOptions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = (values: any) => {
    props.onChange(values.map((value: { label: any; }) => value.label));
  }

  return (
    <FormControl>
      <Select
        onChange={onChange}
        placeholder="Select topic"
        closeMenuOnSelect={false}
        width="500px"
        options={topics}
        isMulti
      />
    </FormControl>
  );
};

export default Topics;
