/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { ReactNode, useEffect } from "react";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AddCrawler } from "./AddCrawler";
import env from "react-dotenv";
import { useState } from "react";

const Links = ["Dashboard"];

const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={"#"}
  >
    {children}
  </Link>
);

function Admin() {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accessToken, setAccessToken] = useState("");
  const [crawlers, setCrawlers] = useState([]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user update:current_user_metadata",
        });
        setAccessToken(accessToken);
        getCrawlers(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    const getCrawlers = async (token: string) => {
      const response = await fetch(`${env.API_URL}/crawlers`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .catch(console.error);
      setCrawlers(response);
    };

    getUserMetadata();
  }, []);

  const onAdd = (data: {
    name: string;
    startDate: string;
    endDate: string;
    enabled: boolean;
  }) => {
    fetch(`${env.API_URL}/crawlers`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(() => window.location.reload())
      .catch(console.error);
  };

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <Image boxSize="7" src="/favicon.ico" alt="Ph-hunter" />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
              variant={"solid"}
              colorScheme={"teal"}
              size={"sm"}
              mr={4}
            >
              Logout
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
              >
                <Avatar size={"sm"} src={user?.picture} />
              </MenuButton>
              {/* <MenuList>
                <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider />
                <MenuItem>Link 3</MenuItem>
              </MenuList> */}
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box p={4}>
        <AddCrawler crawlers={crawlers} onAdd={onAdd} />
      </Box>
    </>
  );
}

export default withAuthenticationRequired(Admin, {
  onRedirecting: () => <div>Redirecting ...</div>,
});
