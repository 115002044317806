import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./Login";
import { Auth0Provider } from "@auth0/auth0-react";
import Admin from "./pages/Admin";
import env from "react-dotenv";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="ph-hunter.au.auth0.com"
      clientId="aIWdjMsDmqfVEjed0FQcYWEzOJo9USxb"
      audience={env.AUTH0_AUDIENCE}
      scope="read:current_user update:current_user_metadata"
      redirectUri={window.location.origin + "/admin"}
    >
      <ChakraProvider>
        <ColorModeScript />
        <Helmet>
          <meta charSet="utf-8" />
          <title>ProductHunt Hunter</title>
          <link rel="canonical" href="ph-hunter.jeremyrajan.com" />
          <meta
            name="description"
            content="ProductHunt Hunter and Aggregator"
          />
        </Helmet>
        <Router>
          <Route path="/" exact>
            <App />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
        </Router>
      </ChakraProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
