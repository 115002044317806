import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons"
import React from "react";
import debounce from 'debounce';

const SearchChildren = () => {
  return (
    <>
      {/* <Switch id="email-alerts" /> */}
      <Search2Icon />
    </>
  )
}

const Search = (props: { onChange: (arg0: any) => void; }) => {

  const onInput = (event: any) => {
    props.onChange(event.target.value);
  }

  const debouncedSearch = debounce(onInput, 1000);

  return (
    <InputGroup>
      <Input onInput={debouncedSearch} placeholder="Search" />
      <InputRightElement children={<SearchChildren />} />
    </InputGroup>
  )
}

export default Search;