/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Box, ChakraProvider, Grid, theme } from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Post } from "./components/Post";
import { useState } from "react";
import { useEffect } from "react";
import env from "react-dotenv";
import Search from "./components/Search";
import { Spinner, GridItem } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import phlogo from "./ph.png";
import Topics from "./components/Topics";
import StatDash from "./components/Stat";
import Sort from "./components/Sort";

export const App = () => {
  const [state, setState]: any = useState({
    posts: { docs: [], total: 0, limit: 10, page: 1, pages: 1 },
    search: "",
    loading: false,
    topics: "",
    sort: {
      sortField: "createdAt",
      sortType: "desc",
    },
  });

  const [stats, setStats]: any = useState({
    totalRecords: 0,
    totalRecordsTopReviews: 0,
    topVoteCount: 0,
    averagePostsDay: 0,
  });

  const fetchData = async (
    search?: string,
    pageIndex = 1,
    topics = "",
    sort = { sortField: "", sortType: "" }
  ) => {
    let url = search
      ? `${env.API_URL}/?search=${search}&page=${pageIndex}&topics=${topics}`
      : `${env.API_URL}/?page=${pageIndex}&topics=${topics}`;

    if (sort.sortField) {
      url += `&sortField=${sort.sortField}&sortType=${sort.sortType}`;
    }
    const posts = await fetch(url)
      .then((res) => res.json())
      .catch(console.error);
    let docs = [];
    if (!search) {
      state.posts.docs.push(...posts.docs);
      docs = state.posts.docs;
    } else {
      docs = posts.docs;
    }
    const { total, limit, page, pages } = posts;
    setState({
      posts: { docs, total, limit, page, pages },
      search,
      topics,
      sort,
    });
  };

  const fetchStats = async () => {
    const stats = await fetch(`${env.API_URL}/stats`).then((res) => res.json());
    setStats(stats);
  };

  const loadMore = () => {
    fetchData(state.search, state.posts.page + 1, state.topics, state.sort);
  };

  useEffect(() => {
    fetchData(state.search, state.posts.page, state.topics, state.sort);
    fetchStats();
  }, [state.search, state.topics]);

  const items = state.posts.docs.map((post: any, ix: any) => (
    <Post {...post} key={ix} />
  ));

  const search = (searchString: string | undefined, topics: "") => {
    setState({
      posts: { docs: [], total: 0, limit: 10, page: 1, pages: 1 },
      search: searchString,
      topics: topics || state.topics,
      sort: {
        sortField: state.sort.sortField,
        sortType: state.sort.sortType,
      },
    });
  };

  const onTopicChange = (topics = "") => {
    setState({
      posts: { docs: [], total: 0, limit: 10, page: 1, pages: 1 },
      search: state.search,
      topics,
      sort: {
        sortField: state.sort && state.sort.sortField,
        sortType: state.sort && state.sort.sortType,
      },
    });
  };

  const onSort = (sort: any) => {
    setState({
      posts: { docs: [], total: 0, limit: 10, page: 1, pages: 1 },
      search: state.search,
      // topics: state.topics,
      sort: {
        sortField: sort.sortField,
        sortType: sort.sortType,
      },
    });
  };

  return (
    <ChakraProvider theme={theme}>
      <Grid
        h="200px"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem bg="#cc4d29" rowSpan={2} colSpan={1}>
          <img src={phlogo} width="200px" alt="ph" />
        </GridItem>
        <GridItem colSpan={4} paddingTop={5} paddingRight={5}>
          <Box d="flex" alignItems="center">
            <StatDash statName="Total Posts" statNumber={stats.totalRecords} />
            <StatDash
              statName="Total Top Reviews"
              statNumber={stats.totalRecordsTopReviews}
            />
            <StatDash
              statName="Top Vote Count"
              statNumber={stats.topVoteCount}
            />
            <StatDash
              statName="Average Daily Posts"
              statNumber={stats.averagePostsDay}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={4} paddingRight={5}>
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <Box d="flex" alignItems="center" w="100%">
              <Search onChange={search} />
              <Box w="50%" marginLeft={2}>
                <Sort onSort={onSort} />
              </Box>
            </Box>
            <Box d="flex" alignItems="center">
              <Box width="100%">
                <Topics onChange={onTopicChange} />
              </Box>
              <ColorModeSwitcher />
              {/* <SettingsDraw /> */}
            </Box>
          </Grid>
          <InfiniteScroll
            dataLength={state.posts.docs.length} //This is important field to render the next data
            next={loadMore}
            hasMore={state.posts.page <= state.posts.pages}
            loader={<Spinner />}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {items}
          </InfiniteScroll>
        </GridItem>
      </Grid>
    </ChakraProvider>
  );
};
