import {
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import React from "react";

interface IRowData {
  name: string;
  startDate: string;
  endDate: string;
  remainingCount: string;
  enabled: boolean;
}

interface IProps {
  colHeads: string[];
  rows: IRowData[];
  heading: string;
}

const TTable = (props: IProps) => {
  return (
    <Table variant="simple">
      <TableCaption>{props.heading}</TableCaption>
      <Thead>
        <Tr>
          {props.colHeads &&
            props.colHeads.map((head, ix) => <Th key={ix}>{head}</Th>)}
        </Tr>
      </Thead>
      <Tbody>
        {props.rows &&
          props.rows.map((row, ix) => (
            <Tr key={ix}>
              <Td>{row.name}</Td>
              <Td>{row.startDate}</Td>
              <Td>{row.endDate}</Td>
              <Td>{row.remainingCount}</Td>
              <Td>{row.enabled.toString()}</Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};

export default TTable;
