import { StatLabel, StatNumber, Stat, Box } from "@chakra-ui/react";
import React from "react";
import CountUp from "react-countup";

const StatDash = (props: any) => {
  return (
    <Box width="100%" marginRight="5px" borderWidth="1px" borderRadius="lg">
      <Stat colorScheme="linkedin" padding={2}>
        <StatLabel>{props.statName}</StatLabel>
        <StatNumber>
          <CountUp start={0} delay={2} end={props.statNumber} />
        </StatNumber>
        {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
      </Stat>
    </Box>
  );
};

export default StatDash;
